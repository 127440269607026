import { useState } from 'react';
import './login.css';
import WaitingPanel from './WaitingPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Login(){
    const [isLoading,setLoading]=useState(false)
    const navigate=useNavigate()
    const [loginForm,setLoginForm]=useState({
        username:'',password:''
    })
    const handle=(e)=>{
        setLoginForm({
            ...loginForm,
            [e.target.name]:e.target.value
        })
    }
    const onLogin=async(e)=>{
        e.preventDefault()
        setLoading(true)
        const res = await fetch('https://api.leadsoftbackend.com//api/student/login',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(loginForm)
        })
        const data = await res.json()
        setLoading(false)
        if(data.status){
            toast.success(data.message)
            localStorage.setItem('student',loginForm.username)
            setTimeout(() => {
                navigate('/')
            }, 1500);
        } else {
            if(data.message==='User not found !!'){
                toast.error(data.message)
                setLoginForm({
                    username:'',password:''
                })
            } else {
                toast.error('Wrong password !!')
                setLoginForm({
                    ...loginForm,
                    password:''
                })
            }
        }
    }
    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            { isLoading && <WaitingPanel/>}
            <div className='background111'>
                <div className='login'>
                    <div className='title'>
                        <h2>Login here !!</h2>
                    </div>
                    <form onSubmit={onLogin}>
                        <div className='input-field'>
                            <label>Username</label>
                            <div className="input-control">
                                <i className="fa-solid fa-graduation-cap"></i>
                                <input type='email' name='username'  required autoComplete="username" onChange={handle} value={loginForm.username} placeholder='Email address'/>
                            </div>
                        </div>
                        <div className='input-field'>
                            <label>Password</label>
                            <div className="input-control">
                                <i className="fa-solid fa-key"></i>
                                <input type='password' name='password' required autoComplete="password" value={loginForm.password} onChange={handle} placeholder='Password'/>
                            </div>
                        </div>
                        <input type='submit' value='Login'/>
                    </form>
                </div>
            </div>
            <div className='footer1'>
                <p>&copy; LeadSoft Placement Academy</p>
            </div>
        </>
    );
}