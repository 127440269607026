import './style.css';
import './actual.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ActualTest(){   
    const useQuery=()=>{
       return new URLSearchParams(useLocation().search) 
    } 
    const query=useQuery();
    const examId=atob(query.get('examId'))   
    const navigate=useNavigate() 
    const examCategory=atob(query.get('examCategory'))        
    const [studentDetail,setStudentDetail]=useState({})
    const getStudentDetails=async()=>{
        let emailAdd=localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/
            SELECT * FROM students WHERE EmailAddress='${emailAdd}'`)
        const data = await res.json()
        if(data.status){
            setStudentDetail(data.message[0])            
        }
    }
    useEffect(()=>{
        const timer=setTimeout(() => {
            getStudentDetails()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
        
    },[])
    const [sections,setSections]=useState([])
    const getSections=async()=>{
        const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/
            SELECT * FROM exam_sections WHERE ExamId=${examId}`)
        const data = await res.json()
        if(data.status){
            setSections(data.message)
            let total=0,totalM=0
            data.message.forEach(element => {
                total+=parseInt(element.Duration)
                totalM+=parseInt(element.SectionMarks)
            });
            setTotalDuration(total)
            setTotalmarks(totalM)
        }
    }
    
    const [totalDuration,setTotalDuration]=useState(0)
    const [totalMarks,setTotalmarks]=useState(0)
    useEffect(()=>{
        const timer=setTimeout(() => {
            getSections()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    })
    const[agree,setAgree]=useState(false)
    const startExam = async() => {
        let emailAddress =localStorage.getItem('student')
        const res = await fetch('https://api.leadsoftbackend.com//api/student/startExam',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                examId:examId,
                emailAddress:emailAddress,
                totalMarks:totalMarks
            })
        })
        const data = await res.json()
        console.log(data)
        if(data.status){
            navigate(`/questions?examId=${btoa(examId)}&examCategory=${btoa(examCategory)}`)
        }
        
    };
    return(
        <>
             

            <div className='actual-container'>
                <div className='actual-header'>
                    <h2>Welcome,{' '+studentDetail.FullName}</h2>
                    <button disabled={!agree} onClick={startExam}>Start Test</button>
                </div>
                <div className='actual-sections'>
                    <div className='instructions'>
                        <div className='title'>
                            <h2>Instructions For Online Exam</h2>
                        </div>
                        <ul>
                            <li>Do not refresh your browser or press the back button. This may result in losing your progress</li>
                            <li>Do not open multiple tabs or windows. This could trigger an automatic termination of your test session.</li>
                            <li>Use the "Next" button to move to the next question. You cannot go back to previous questions once you have moved forward.</li>
                            <li>A timer will be displayed on the screen showing the remaining time for the entire test.</li>
                            <li>The total duration of the test is <strong>{totalDuration}</strong> minutes.</li>
                            <li>The total marks of the test is <strong>{totalMarks}</strong> marks.</li>
                            <li>Sections: The test is divided into the following sections:
                                <ul style={{padding:'5px 30px'}}>
                                    {
                                        sections.map((item,index)=>{
                                            return(
                                                <li key={index}>Section {index+1} : 
                                                {item.SectionName} - <strong>{item.NoOfQuestion}</strong> questions and <strong>{item.Duration}</strong> minutes</li>
                                            )
                                        })
                                    }
                                    
                                </ul>
                            </li>
                            <li>Choose a quiet place with minimal distractions. Ensure a stable internet connection.</li>
                        </ul>
                    </div>
                    <div className='check'>
                        <input type='checkbox' value={agree} 
                        onChange={()=>setAgree(!agree)}></input>
                        <p>By checking the box, you acknowledge that you have read and understood the instructions above.</p>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default ActualTest;