import { useLocation, useNavigate } from 'react-router-dom';
import './TestResult.css';
import { useEffect, useState } from 'react';

export default function TestResult(){
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const query=useQuery();
    
    const examId=atob(query.get('examId'))   
    const navigate=useNavigate()
    const [examDetails,setExamDetails]=useState({})
    const [examSections,setExamSections]=useState([])
    const [examResult,setExamResult]=useState([])
    
    useEffect(()=>{
        const getExamDetails=async()=>{
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT ExamCategory,ExamName,TotalDuration,TotalMarks FROM exams WHERE SrNo=${examId}`)
            const data = await res.json()
            if(data.status){
                setExamDetails(data.message[0])                
            }
        }
        const getExamResult=async()=>{
            let emailAddress = localStorage.getItem('student')
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT ObtainedMarks,TimeTaken,Status FROM test_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
            const data = await res.json()
            if(data.status){
                setExamResult(data.message[0])                
            }
        }
        const getExamSections=async()=>{
            let emailAddress = localStorage.getItem('student')
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM test_section_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
            const data = await res.json()
            console.log(data)
            if(data.status){
                setExamSections(data.message)
            }
        }
        const timer=setTimeout(() => {
            getExamDetails()
            getExamSections()
            getExamResult()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    },[])
    const viewAnswers=()=>{
        const newTabUrl = `${window.location.origin}/test-solution?examId=${btoa(examId)}`;
        window.open(newTabUrl, '_blank');
    }
    return(
        <>
            <div className='result-background'>
                <div className='box'>
                    <div className='title'>
                        <h2>Test Result</h2>
                    </div>
                    <div className='details'>
                        {Object.entries(examDetails).map(([key, value]) => (
                            <div key={key} className='item'>                                                            
                                <label>{key}</label>
                                <p>{(key==='ExamCategory' || key==='ExamName') ? value : (key==='TotalMarks' ? value+' marks' : value+' mins')}</p>
                            </div>
                        ))}                        
                    </div>
                    <div className='table-title'>
                            <h2>Test Analysis</h2>
                    </div>
                    <table>                        
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Section Name</th>
                                <th>Duration(mins)</th>
                                <th>Time Taken</th>
                                <th>Question Type</th>
                                <th>Marks</th>
                                <th>Correct</th>
                                <th>Wrong</th>
                                <th>Skip</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                examSections.length > 0 && examSections.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.SectionName}</td>
                                            <td>{item.Duration}</td>
                                            <td>{item.TimeTaken}</td>
                                            <td>{item.QuestionType}</td>
                                            <td>{item.ObtainedMark+'/'+item.SectionMarks}</td>
                                            <td>{item.Positive}</td>
                                            <td>{item.Negative}</td>
                                            <td>{item.Skipped}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className='details' style={{marginTop:'20px'}}>
                        {Object.entries(examResult).map(([key, value]) => (
                            <div key={key} className='item'>                                                            
                                <label>{key}</label>
                                <p>{key==='ObtainedMarks'  ? value+' marks' : value}</p>
                            </div>
                        ))}      
                        <div className='item'>                                                            
                                <label>Actions</label>
                                <button onClick={()=>viewAnswers()}>View Your Answers</button>
                            </div>                  
                    </div>
                </div>
            </div>
        </>
    )
}