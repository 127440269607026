import './style.css';
import './questions.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WaitingPanel from './WaitingPanel';
import { addData,clearData,getAllData,updateData } from './indexedDb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Questions(){
    const elementRef = useRef(null);
    const [time, setTime] = useState(0)
    const [formData, setFormData] = useState({
        SectionName: '',SectionMarks: '',NoOfQuestions: '',Duration: '',
        Answer: '',CompanyTags: '',Option1: '',Option2: '',Option3: '',
        Option4: '',QuestionContent: ''
    });
    const [items, setItems] = useState([]);
    const [isLoading,setLoading]=useState(true)
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const query=useQuery();
    const examId=atob(query.get('examId'))   
    const navigate=useNavigate() 
    const examCategory=atob(query.get('examCategory'))         
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [sections,setSections]=useState([])
    const [currentSectionIndex,setCurrentSectionIndex]=useState(0)
    const [currentItemIndex,setCurrentItemIndex]=useState(0)
    const [fullName,setFullName]=useState('')
    useEffect(()=>{
        const getSectionDetails=async()=>{   
            await clearData()         
            setLoading(true)
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM exam_sections WHERE ExamId=${examId}`)
            const data = await res.json()
            if(data.status){
                data.message.forEach(element=>{
                    element.TimeTaken='0';
                    element.Attempted=0;
                    element.Skipped=0
                })
                setAttempted(0)
                setSkipped(0)
                setSections(data.message)     
            }
            setLoading(false)
        }
        const timer=setTimeout(() => {
            getSectionDetails()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
        
       
    },[])
    const convertToSeconds = (time) => {
        const [minutes, seconds] = time.split(":").map(Number);
        return minutes * 60 + seconds;
      };
    
      const convertToMinSec = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      };
      
    
     
      const calculateDifference = (durationMinutes,currentTime) => {
        const seconds1=durationMinutes*60
        const seconds2=convertToSeconds(currentTime)                
        const diffInSeconds = seconds1 - seconds2;
        return convertToMinSec(diffInSeconds);
      };
    
    useEffect(() => {
        if (time <= 0){
            return;
        }
    
        const intervalId = setInterval(() => {
          setTime(prevTime => prevTime - 1);
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, [time]);
      const formatTime = time => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      };
    const getname=async()=>{
        let emailAddress = localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT FullName FROM students WHERE EmailAddress='${emailAddress}'`)
        const data = await res.json()
        if(data.status){
            setFullName(data.message[0].FullName)                
        }
    }       
    const getQuestions=async(sectionDetails)=>{        
        if (sectionDetails === undefined) return;
        setLoading(true)
        await getname();
        if (sectionDetails) {            
            let questionType = '', chaptername = sectionDetails.ChapterName,subjectName = sectionDetails.SubjectName;
            let noOfQuestions = sectionDetails.NoOfQuestion, duration = sectionDetails.Duration;
            let temp = { 
                SectionName: sectionDetails.SectionName,SectionMarks: sectionDetails.SectionMarks,NoOfQuestions: sectionDetails.NoOfQuestion,
                Duration: sectionDetails.Duration,Answer: '', CompanyTags: '', Option1: '', Option2: '', Option3: '',Option4: '', 
                QuestionContent: '',StudentAnswer:'',Status:''
            };

            if (sectionDetails.QuestionType === 'MCQ') {
                questionType = 'Single Choice Question';
            }

            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT QuestionContent,Option1,Option2,Option3,Option4,Answer,CompanyTags FROM questions WHERE (QuestionType='${questionType}' AND SubjectName='${subjectName}') AND (ChapterName='${chaptername}') ORDER BY RAND() LIMIT ${noOfQuestions}`);            
            const data = await res.json();
            await clearData();
            if (data.status) {
                for (let i = 0; i < noOfQuestions; i++) {
                    temp.Answer = data.message[i].Answer;
                    temp.CompanyTags = data.message[i].CompanyTags;
                    temp.Option1 = data.message[i].Option1;
                    temp.Option2 = data.message[i].Option2;
                    temp.Option3 = data.message[i].Option3;
                    temp.Option4 = data.message[i].Option4;
                    temp.QuestionContent = data.message[i].QuestionContent;
                    await addData(temp);
                }
            }
            const data1 = await getAllData();                        
            setLoading(false)
            setItems(data1);
        }
        
    }
    
    const requestFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { 
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { 
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { 
            element.msRequestFullscreen();
        }
    };
    const [attempted,setAttempted]=useState(0)
    const [skipped,setSkipped]=useState(0)
    const handleFullscreen = () => {
        if(isFullscreen===false){
            getQuestions(sections[currentSectionIndex])
        }
        if (elementRef.current) {
            requestFullscreen(elementRef.current);
        }
    };
    const handleFullscreenChange = () => {
        setIsFullscreen(!!document.fullscreenElement);        
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);
    const submitExam=async(examId,emailAddress)=>{
        const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT SUM(ObtainedMark) AS ObtainedMark FROM test_section_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
        const data= await res.json()
        console.log(data)
        let obtainedMarks=0
        if(data.status){
            obtainedMarks+=parseInt(data.message[0].ObtainedMark)
        }
        let timeTaken="00:00"
        const res1 = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT TimeTaken FROM test_section_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
        const data1= await res1.json()        
        if(data1.status){
            for (let index = 0; index < data1.message.length; index++) {
                const element = data1.message[index];
                timeTaken=addTimes(timeTaken,element.TimeTaken)
            }            
        }
        const res2= await fetch('https://api.leadsoftbackend.com//api/student/submitExam',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                examId:examId,
                emailAddress:localStorage.getItem('student'),
                marks:obtainedMarks,
                timeTaken:timeTaken
            })
        })
        const data2 = await res2.json()
        await clearData()
        if(data2.status){
            toast.success(data.message)
        } else {
            toast.error(data.message)
        }

    }
    const addTimes = (time1, time2) => {
        const totalSeconds = convertToSeconds(time1) + convertToSeconds(time2);
        return convertToMinSec(totalSeconds);
      };
    const nextQuestion=async(e)=>{
        e.preventDefault()        
        let updatedItem={...items[currentItemIndex]}
        updatedItem.StudentAnswer=answer
        if(updatedItem.StudentAnswer===updatedItem.Answer)    {
            updatedItem.Status="Correct"
        } else {
            updatedItem.Status="Wrong"
        }
        await updateData(updatedItem)
        .then(async(result) => {   
            setAttempted(attempted=>attempted+1)     
            setLoading(true)  
            if(currentItemIndex===items.length-1){   
                
                let data123 = await getAllData()                
                let correct=0,wrong=0,skip=0;
                data123.forEach(element => {
                    if(element.Status==="Correct")  correct+=1
                    else if(element.Status==="Wrong") wrong+=1
                    else if(element.Status==="Skipped") skip+=1
                });                
                let emailAddress=localStorage.getItem('student')
                let sectionName =sections[currentSectionIndex].SectionName
                let duration=sections[currentSectionIndex].Duration
                let noOfQuestions=sections[currentSectionIndex].NoOfQuestion
                let sectionMarks=sections[currentSectionIndex].SectionMarks
                const timeTaken = calculateDifference(sections[currentSectionIndex].Duration, formatTime(time))
                let questionType=sections[currentSectionIndex].QuestionType               
                const result = submitSection(emailAddress,examId,sectionName,duration,noOfQuestions,sectionMarks,timeTaken,questionType,correct,wrong,correct,skip)
                if(result){
                    let emailAddress=localStorage.getItem('student')
                    let sectionName =sections[currentSectionIndex].SectionName
                    let flag=true
                    for (let index = 0; index < data123.length; index++) {
                        const element = data123[index];
                        const confirmed = submitSectionQuestion(emailAddress,examId,sectionName,element.QuestionContent,element.Option1,element.Option2,element.Option3,element.Option4,element.Answer,element.StudentAnswer,element.Status)
                        if(!confirmed){
                            flag=false
                            break;
                        }
                    }
                    if(flag===true){
                        if(currentSectionIndex===((sections.length)-1)){   
                            setLoading(true)                 
                            setTimeout(async() => {
                               submitExam(examId,localStorage.getItem('student'))
                               let encodedArg1=btoa(examId)                    
                               setIsFullscreen(false)           
                               navigate(`/test-result?examId=${encodedArg1}`)
                            }, 5000);
                            setLoading(false)                 
                        } else {                    
                            setCurrentItemIndex(0)                                  
                            setCurrentSectionIndex(currentSectionIndex+1)    
                        } 
                    } else {
                        toast.error('Error while submitting the questions !!')
                    }
                    
                } 
                else {
                    toast.error('Error while submitting the section !!')
                }                    
                            
            } else {
                setCurrentItemIndex(currentItemIndex+1)            
            }         
            setLoading(false)   
            setStudentAnswer('')      
        })
        .catch(error => {
            setLoading(false)  
            console.error('Error updating item:', error);
        });         
    }
    const skipQuestion=async()=>{ 
        let updatedItem={...items[currentItemIndex]}
        updatedItem.StudentAnswer=answer
        updatedItem.Status="Skipped"
        await updateData(updatedItem)
        .then(async(result) => {   
            setSkipped(skipped=>skipped+1)    
            setLoading(true)                         
            if(currentItemIndex===items.length-1){   
                               
                let data123 = await getAllData()                
                let correct=0,wrong=0,skip=0;
                data123.forEach(element => {
                    if(element.Status==="Correct")  correct+=1
                    else if(element.Status==="Wrong") wrong+=1
                    else if(element.Status==="Skipped") skip+=1
                });
                let emailAddress=localStorage.getItem('student')
                let sectionName =sections[currentSectionIndex].SectionName
                let duration=sections[currentSectionIndex].Duration
                let noOfQuestions=sections[currentSectionIndex].NoOfQuestion
                let sectionMarks=sections[currentSectionIndex].SectionMarks
                const timeTaken = calculateDifference(sections[currentSectionIndex].Duration, formatTime(time))
                let questionType=sections[currentSectionIndex].QuestionType               
                const result = submitSection(emailAddress,examId,sectionName,duration,noOfQuestions,sectionMarks,timeTaken,questionType,correct,wrong,correct,skip)
                if(result){
                    let emailAddress=localStorage.getItem('student')
                    let sectionName =sections[currentSectionIndex].SectionName
                    let flag=true
                    for (let index = 0; index < data123.length; index++) {
                        const element = data123[index];
                        const confirmed = submitSectionQuestion(emailAddress,examId,sectionName,element.QuestionContent,element.Option1,element.Option2,element.Option3,element.Option4,element.Answer,element.StudentAnswer,element.Status)
                        if(!confirmed){
                            flag=false
                            break;
                        }
                    }
                    if(flag===true){
                        if(currentSectionIndex===((sections.length)-1)){                    
                            setLoading(true)                 
                            setTimeout(async() => {
                               submitExam(examId,localStorage.getItem('student'))
                            }, 5000);
                            setLoading(false)    
                        } else {                    
                            setCurrentItemIndex(0)                                  
                            setCurrentSectionIndex(currentSectionIndex+1)    
                        } 
                    } else {
                        toast.error('Error while submitting the questions !!')
                    }
                    
                } 
                else {
                    toast.error('Error while submitting the section !!')
                }                                    
            } else {
                setCurrentItemIndex(currentItemIndex+1)     
            }
            setLoading(false)
            setStudentAnswer('')      
        })
        .catch(error => {
            setLoading(false)
            console.error('Error updating item:', error);
        });         
    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; 
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
    useEffect(()=>{
        if(sections.length>0){
            setTime(sections[currentSectionIndex].Duration*60)
            setCurrentItemIndex(0)        
            setAttempted(0) 
            setSkipped(0)  
        }
        getQuestions(sections[currentSectionIndex])
        
    },[sections,currentSectionIndex])
    const [answer,setStudentAnswer]=useState('')
    const handleChange = (selectedAnswer) => {
        setStudentAnswer(selectedAnswer); 
    };
    const submitSectionQuestion=async(emailAddress,examId,sectionName,questionContent,option1,option2,
        option3,option4,answer,candidateAnswer,status)=>{
        const res = await fetch('https://api.leadsoftbackend.com//api/student/submitSectionQuestions',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                emailAddress:emailAddress,examId:examId,sectionName:sectionName,
                questionContent:questionContent,option1:option1,option2:option2,option3:option3,
                option4:option4,answer:answer,candidateAnswer:candidateAnswer,status:status
            })
        })
        const data = await res.json()
        if(data.status){
            return true
        } else {
            return false
        }
    }
    const submitSection=async(emailAddress,examId,sectionName,duration,noOfQuestions,sectionMarks,timeTaken,questionType,positive,negative,obtainedMark,skipped)=>{
        const res = await fetch('https://api.leadsoftbackend.com//api/student/submitSection',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                emailAddress:emailAddress,examId:examId,sectionName:sectionName,
                noOfQuestions:noOfQuestions,sectionMarks:sectionMarks,timeTaken:timeTaken,
                questionType:questionType,positive:positive,duration:duration,
                negative:negative,obtainedMark:obtainedMark,skipped:skipped
            })
        })
        const data = await res.json()
        if(data.status){
            return true
        } else {
            return false
        }
    }
    return(
        <>
            
            <div className='actual-container' ref={elementRef}>
                {
                    
                    !isFullscreen ? (<>
                        <div className='card'>
                            <h2>Allow fullscreen to proceed with the test?</h2>
                            <button onClick={handleFullscreen}>Allow Fullscreen</button>
                        </div>
                    </>) :(
                        <>
                            <ToastContainer
                                position="top-right"
                                autoClose={2500}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                            { isLoading && <WaitingPanel/> }
                            <div className='questions-header'>
                                <h2>LeadSoft Placement Academy</h2>
                            </div>
                            <div className='question-container'>
                                <div className='section-details'>    
                                    <h2>{items.length> 0 && sections[currentSectionIndex].SectionName}</h2>                                
                                    <h2>{formatTime(time)}</h2>
                                </div>
                                <div className='questions'>
                                    <div className='question-details'>
                                        <p style={{fontWeight:'bold',fontSize:'14px'}}>Question No : {currentItemIndex+1}/{items.length> 0 && items[currentItemIndex].NoOfQuestions}</p>

                                        <p dangerouslySetInnerHTML={{__html:items.length> 0 && items[currentItemIndex].QuestionContent}} style={{marginTop:'15px',padding:'8px 0px'}}></p>
                                        <form onSubmit={nextQuestion}>
                                        <div className='options'>
                                            <div className='option'>
                                                <label className="custom-radio">
                                                    <input type="radio" name="answer" value="Option 1" checked={answer==="Option 1"} onChange={()=>handleChange("Option 1")}/>
                                                    <div className="checkmark"></div>                                                    
                                                    <p dangerouslySetInnerHTML={{__html:items.length> 0 && ' A. '+items[currentItemIndex].Option1}}></p>
                                                </label>    
                                            </div>
                                            <div className='option'>
                                                <label className="custom-radio">
                                                    <input type="radio" name="answer" value="Option 2" checked={answer==="Option 2"} onChange={()=>handleChange("Option 2")} />
                                                    <div className="checkmark"></div>                                                    
                                                    <p dangerouslySetInnerHTML={{__html:items.length> 0 && ' B. '+items[currentItemIndex].Option2}}></p>
                                                </label>    
                                            </div>
                                            <div className='option'>
                                                <label className="custom-radio">
                                                    <input type="radio" name="answer" value="Option 3" checked={answer==="Option 3"} onChange={()=>handleChange("Option 3")} />
                                                    <div className="checkmark"></div>                                                    
                                                    <p dangerouslySetInnerHTML={{__html:items.length> 0 && ' C. '+items[currentItemIndex].Option3}}></p>
                                                </label>    
                                            </div>
                                            <div className='option'>
                                                <label className="custom-radio">
                                                    <input type="radio" name="answer" value="Option 4" checked={answer==="Option 4"} onChange={()=>handleChange("Option 4")} />
                                                    <div className="checkmark"></div>                                                    
                                                    <p dangerouslySetInnerHTML={{__html:items.length> 0 && ' D. '+items[currentItemIndex].Option4}}></p>
                                                </label>    
                                            </div>                                            
                                        </div>
                                        <div className='user-details'>
                                            <p>Skipped : <span>{skipped}</span></p>
                                            <p>Attempted : <span>{attempted}</span></p>                                                
                                        </div>
                                        <div className='actions-question'>
                                            
                                            <div>
                                                <button type='reset' onClick={()=>skipQuestion()} >Skip Question</button>
                                                <button disabled={answer===''} type='submit'>{currentItemIndex===items.length-1 ? 'Save & Finish Section':'Save & Next'}</button>
                                            </div>
                                            <div className='tags'>
                                                {
                                                    items.length > 0 && items[currentItemIndex].CompanyTags.split(',').map((item,index)=>{
                                                        return(
                                                            <p key={index}>{item}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        </form>
                                        
                                    </div>                                    
                                    
                                </div>
                                <div className='footer2'>
                                   <h2>{fullName} | </h2>
                                   <h2>{localStorage.getItem('student')} | </h2>
                                   <h2>{examCategory}</h2>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}