import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import './Test.css';
import { useEffect, useState } from 'react';

export default function Exams(){    
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const navigate=useNavigate()
    const login=()=>{
        navigate('/login')
    }
    const query=useQuery();
    const categoryName=atob(query.get('param1'))
    const [exams,setExams]=useState([])
    useEffect(() => {
        const fetchAllExams = async () => {
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM exams WHERE ExamCategory='${categoryName}'`)
            const data = await res.json()
            if (data.status) {
                setExams(data.message)
            }
        }
        const timer=setTimeout(() => {
            fetchAllExams()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
        
    }) 
    const getExamActualStatus = async (examId) => {
        let emailAddress = localStorage.getItem('student')
        const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT Status FROM test_result WHERE ExamId=${examId} AND EmailAddress='${emailAddress}'`)
        const data = await res.json()
        if (data.status) {                        
            if(data.message.length > 0 && data.message[0].Status){
                return data.message[0].Status==='COMPLETED'
            }
            return false
            
        }
    }
    const getExamStatus=(arg)=>{
        const givenDate = new Date(arg);
        const currentDate = new Date();
        if (givenDate < currentDate) {
            return false
        } else if (givenDate > currentDate) {
            return true
        } else {
            return true
        }        
    }
    const navigateToTest=()=>{
        navigate('/test')
    }
    const openExam=async(arg1,arg2)=>{
        let check=await getExamActualStatus(arg1)
        console.log(check)
        if(check){
            const newTabUrl = `${window.location.origin}/test-solution?examId=${btoa(arg1)}`;
            window.open(newTabUrl, '_blank');
            return
        }
        const encodedParameter1 = btoa(arg1.toString())
        const encodedParameter2 = btoa(arg2.toString())
        navigate(`/exam?examId=${encodedParameter1}&examCategory=${encodedParameter2}`);
    }
    const getDate=(isoString)=>{
        const date = new Date(isoString);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // This makes the time display in 12-hour format with AM/PM
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate
    }
    return(
        <>
            <Header/>
            {
                localStorage.getItem('student')===null ? (
                    <div className='login-information'>
                        <h2>Please Login first !!</h2>
                        <button onClick={login}>Click here for login </button>
                    </div>
                ) : (
                    <>
                    <div className='exam-category'>
                        <div className='title'>
                            <h2>Your Exams</h2>
                        </div>
                        <div className="back">
                            <i className="fa-solid fa-chevron-left"></i>
                            <p onClick={()=>navigateToTest()}>Back to dashboard</p>
                        </div>
                        <div className='category'>
                            {
                                exams.length > 0 ? (exams.map((item,index)=>{
                                    return(
                                        <div className='card' key={index}>
                                            <div className='card-title'>                                                
                                                <h2>{item.ExamName}</h2>
                                                <p>LeadSoft</p>
                                            </div>
                                            <div className="card-content">
                                                <p className={getExamStatus(item.ExamTo) ? 'active1':'expired'}>{
                                                    getExamStatus(item.ExamTo) ? (getExamActualStatus(item.SrNo) 
                                                    ? 'Attempted':'Active'):'Expired'}</p>
                                                <div className="box">
                                                    <i className="fa-regular fa-clock"></i>
                                                    <strong>{getDate(item.ExamFrom) }</strong> to <strong>{getDate(item.ExamTo)}</strong>
                                                </div>
                                                <div className="box">
                                                    <i className="fa-solid fa-bookmark"></i>
                                                    <strong>{item.TotalMarks} Marks</strong>
                                                </div>
                                                <div className="box">
                                                    <i className="fa-solid fa-hourglass-end"></i>
                                                    <strong>{item.TotalDuration} mins</strong>
                                                </div>
                                                <button onClick={()=>openExam(item.SrNo,item.ExamCategory)} disabled={!getExamStatus(item.ExamTo)}>{getExamStatus(item.ExamTo) ? (getExamActualStatus(item.SrNo) ? 'View Solution':'Start'):'Start' }</button>
                                            </div>
                                            
                                        </div>
                                    )
                                })) :(<>
                                    <p>No Exams Found !!</p>
                                </>)
                            }
                        </div>
                    </div>
                    </>
                )
            }
            <Footer/>
        </>
    )
}