import './style.css';
import './courses.css';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';

export default function SelectedCourse(){

    const [chapters,setChapters]=useState([])
    const [videoId,setVideoId]=useState('')    
   
    const selectChapter=(index,vid)=>{        
        setVideoId(videoId=>vid)
        setIndex(currentIndex=>index)
    }   
    useEffect(()=>{
        const getDataFromFile = async () => {
            const res = await fetch('https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM course_chapters WHERE CourseId='+courseId)
            const data = await res.json()
            if(data.status){
                 setChapters(data.message)
                 setVideoId(data.message[0].VideoId)
            }        
         };
        const timer=setTimeout(() => {
            getDataFromFile()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    },[])
    useEffect(()=>{
        const timer=setTimeout(() => {
            getCourseOverview()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    },[])
    const YouTubeEmbed = ({ videoId }) => (
        <div className="video-responsive">
            <iframe                               
                src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&showinfo=0&autohide=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={''}
            ></iframe>
        </div>
    );

    
    
    const [currentIndex,setIndex]=useState(0)    
    const useQuery=()=>{
        return new URLSearchParams(useLocation().search)
    }
    const query=useQuery();
    const courseName=atob(query.get('param1'))
    const courseId=atob(query.get('param2'))
    const [courseOverview,setCourseOverview]=useState('')
    const getCourseOverview=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM courses WHERE SrNo='+courseId)
        const data = await res.json()        
        if(data.status){
            setCourseOverview(data.message[0].CourseOverview)
        }
    }
    return(
        <>
            <Header/>
            <div className='overview'>
                <div className='title'>
                    <h2>{courseName} | Overview</h2>
                    <p>{courseOverview}</p>
                </div>                                
            </div>
            <div className='course-content1'>
                    <div className='video'>
                        <YouTubeEmbed videoId={videoId}/>
                    </div>
                    <div className='chapters'>
                        <div className='title'>
                            <h2>Chapters - {chapters.length}</h2>
                        </div>
                        <div className='list'>
                            {
                                chapters.length > 0 ? chapters.map((item,index)=>{
                                    return(
                                        <div className='items' key={index}>
                                            <button className={currentIndex===index ? 'selected':''} onClick={()=>selectChapter(index,item.VideoId)} title={item.Title}> <i className="fa-solid fa-circle-play"></i> {item.Title}</button>
                                        </div>
                                    )
                                }):(
                                    <p>No Chapters Available</p>
                                )
                            }
                        </div>
                    </div>
            </div>           
            <Footer/>
        </>
    )
}