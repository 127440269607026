import { Link, NavLink, useNavigate } from 'react-router-dom';

export default function Header(){
    const navigate=useNavigate()
    const logout=()=>{
        if(localStorage.getItem('student')===null){
            navigate('/login')
        } else {
            localStorage.removeItem('student')
            navigate('/')
        }
        
    }
    return(
        <div className='header'>
            <Link className='title' to='/'>LeadSoft</Link>
            <div className='actions'>
                <NavLink to='/'>About</NavLink>
                <NavLink to='/courses'>Courses</NavLink>
                <NavLink to='/test'>Test</NavLink>
                <NavLink to='/profile'>Profile</NavLink>
                <button onClick={()=>logout()} className={localStorage.getItem('student')===null ? '' : 'logout'}>{localStorage.getItem('student')===null ? 'Login' : 'Logout'}</button>
            </div>
        </div>
    );
}