import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage';
import Courses from './components/Courses';
import SelectedCourse from './components/SelectedCourse';
import Test from './components/Test';
import Login from './components/Login';
import Exams from './components/Exams';
import ActualTest from './components/ActualTest';
import Questions from './components/Questions';
import TestResult from './components/TestResult';
import ViewSolutions from './components/ViewSolutions';
import Profile from './components/Profile';
function App() {
  return (
    <>
        <div className='background'>
          <BrowserRouter>          
              <Routes>
                  <Route path='/' Component={Homepage}/>
                  <Route path='/courses' Component={Courses}/>
                  <Route path='/profile' Component={Profile}/>
                  <Route path='/courses/selected' Component={SelectedCourse}/>
                  <Route path='/test' Component={Test}/>
                  <Route path='/test/exams' Component={Exams}/>
                  <Route path='/exam' Component={ActualTest}/>
                  <Route path='/questions' Component={Questions}/>
                  <Route path='/login' Component={Login}/> 
                  <Route path='/test-result' Component={TestResult}/>                  
                  <Route path='/test-solution' Component={ViewSolutions}/>
              </Routes>  
          </BrowserRouter>
        </div>
    </>
  );
}

export default App;
