import { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import './style.css';
import male from '../assets/man.webp'
import female from '../assets/woman.webp';
import WaitingPanel from './WaitingPanel';

export default function Profile(){
    const [isLoading,setLoading]=useState(false)
    const [studentInfo,setStudentInfo]=useState({})
    useEffect(()=>{
        const fetchStudentData=async()=>{
            setLoading(true)
            let emailAddress = localStorage.getItem('student')
            const res = await fetch(`https://api.leadsoftbackend.com//api/student/getData/SELECT * FROM students WHERE EmailAddress='${emailAddress}'`)
            const data = await res.json()
            if(data.status){
                setStudentInfo(data.message[0])                
            }
            setLoading(false)
        }   
        const timer=setTimeout(() => {
            fetchStudentData()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    },[])
    return(
        <>
        {
            isLoading && <WaitingPanel/>
        }
            <Header/>
            <div className="profile-container">
                <div className="image-container">
                    <div className="circle">
                        <img alt='Profile Picture' src={studentInfo.Gender==='Male' ? male : female}/>
                    </div>
                    <div className="content">
                        <div className="box">
                            <p>Reg. Id</p><p>{studentInfo ? studentInfo.Id :''}</p>                            
                        </div>
                        <div className="box">
                            <p>Fullname</p><p>{studentInfo ? studentInfo.FullName :''}</p>
                        </div>
                        <div className="box">
                            <p>College </p><p>{studentInfo ? studentInfo.CollegeName :''}</p>                            
                        </div>
                        <div className="box">
                            <p>Batch</p><p>{studentInfo ? studentInfo.BatchName :''}</p>
                        </div>
                        <div className="box">
                            <p>Mobile</p><p>{studentInfo ? studentInfo.MobileNo :''}</p>
                        </div>
                        <div className="box">
                            <p>DOB</p><p>{studentInfo ? studentInfo.DateOfBirth :''}</p>
                        </div>
                    </div>
                </div>
                <div className="detail-container">
                        <p>Waiting for profile analysis...</p>
                        <button disabled>Display Data</button>
                </div>
            </div>
            <Footer/>
        </>
    )
}