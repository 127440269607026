import Header from './Header';
import './style.css';
import './courses.css'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Courses(){
    const navigate=useNavigate()
    const [courses,setCourse]=useState([])
    const selectCourse=(arg1,arg2)=>{        
        const encodedParam1 = btoa(arg1.toString()); 
        const encodedParam2 = btoa(arg2.toString());         
        navigate(`/courses/selected?param1=${encodedParam1}&param2=${encodedParam2}`)
    }
    const getAllCourses=async()=>{
        const res = await fetch('https://api.leadsoftbackend.com//api/student/getAllCourses')
        const data =await res.json()
        console.log(data)
        if(data.status){
            setCourse(data.message)
        }
        
    }
    useEffect(()=>{
        const timer=setTimeout(() => {
            getAllCourses()
        }, 500);
        return(()=>{
            clearTimeout(timer)
        })
    },[])
    return(
        <>
            
            <Header/>
            <div className='title-container'>
                <div className='title'>
                    <h2>Choose your course</h2>
                    <p>At LeadSoft Placement Academy, we offer a diverse range of courses tailored to meet the unique needs and aspirations of engineering graduates. Whether you're looking to enhance your technical skills, develop your aptitude for complex problem-solving, or prepare for competitive campus placements, we have the right course for you.</p>
                </div>
            </div>
            <div className='courses'>
                <div className='list'>
                    {
                        courses.length > 0 ? (courses.map((item,index)=>{
                            return(
                                <>
                                    <div className='card' key={index}>
                                        <img alt='CourseBanner' src={'https://api.leadsoftbackend.com//uploads/courses/'+item.CourseBanner}/>
                                        <div className='card-footer'>
                                            <h2 className='title'>{item.CourseName}</h2>
                                        </div>
                                        <div className='card-action'>
                                            <p>0 of {item.ChapterCount}</p>
                                            <button onClick={()=>selectCourse(item.CourseName,item.SrNo)}>Start now <i className="fa-solid fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </>
                            )
                        })) : (
                            <div className='text-center'>
                                <p> No courses available for you !!</p>
                            </div>
                        )
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}
